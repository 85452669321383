import * as React from "react"

import Logo from '../images/svg/logo-full.inline.svg';

import SEO from "../components/seo"
import Layout from "../components/layout"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <header className="header">
      {/* <img className="header__logo" src="../images/Logo StudioFAS Full.svg" alt="" /> */}
      <Logo className="header__logo" />
      <h1>Studio Fas</h1>
      <h2>Helping your business grow in the connected world.</h2>
    </header>
  </Layout>
);

export default IndexPage
